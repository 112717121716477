import React from 'react';
import PropTypes from 'prop-types';

// UI
// =============================================================================
import Container from 'common/src/components/UI/Container';

// Components
// =============================================================================
import Box from 'common/src/components/Box';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';

// Blocks
// =============================================================================
import BenefitsBlock from './blocks/Benefits';

// Styles
// =============================================================================
import SectionAreaWrapper, { SectionArea } from './BenefitsWithImage.style';

// Colours
// =============================================================================
import brand from 'common/src/theme/landing/brand';

// BenefitsWithImage
// =============================================================================
const BenefitsWithImage = ({
  SectionWrapperStyle,
  RowStyle,
  RowFixedStyle,
  ColStyle,
  ColOneStyle,
  ColTwoStyle,
  BenefitsHeadingStyle,
  BenefitsImageStyle,
  benefitsHeading,
  benefitsImage,
  dataBenefits,
}) => {
  return (
    <Box {...SectionWrapperStyle} id="benefits_image">
      <Container fullWidth>
        <Box {...RowStyle} {...RowFixedStyle}>
          <Box {...ColStyle} {...ColOneStyle}>
            <SectionAreaWrapper>
              <SectionArea>
                <Heading {...BenefitsHeadingStyle} content={benefitsHeading} />
                <BenefitsBlock dataBenefits={dataBenefits} />
              </SectionArea>
            </SectionAreaWrapper>
          </Box>
          <Box {...ColStyle} {...ColTwoStyle}>
            <SectionAreaWrapper>
              <SectionArea>
                <Image
                  src={benefitsImage}
                  {...BenefitsImageStyle}
                  alt="Devices"
                />
              </SectionArea>
            </SectionAreaWrapper>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

BenefitsWithImage.propTypes = {
  SectionWrapperStyle: PropTypes.object,
  RowStyle: PropTypes.object,
  RowFixedStyle: PropTypes.object,
  ColStyle: PropTypes.object,
  ColOneStyle: PropTypes.object,
  ColTwoStyle: PropTypes.object,
  BenefitsHeadingStyle: PropTypes.object,
  BenefitsImageStyle: PropTypes.object,
};

BenefitsWithImage.defaultProps = {
  SectionWrapperStyle: {
    as: 'section',
    bg: brand.brand8,
    pt: ['40px', '50px', '60px', '60px', '80px'],
    pb: ['40px', '50px', '60px', '60px', '80px'],
  },
  RowStyle: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  RowFixedStyle: {
    maxWidth: '1360px',
    width: '100%',
    m: '0 auto',
  },
  ColStyle: {
    height: '100%',
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ColOneStyle: {
    width: ['100%', '100%', '100%', '55%', '55%'],
  },
  ColTwoStyle: {
    width: ['100%', '100%', '100%', '45%', '45%'],
  },
  BenefitsHeadingStyle: {
    fontSize: ['22px', '24px', '24px', '26px', '28px'],
    fontWeight: '500',
    color: '#fff',
    letterSpacing: '0em',
    mb: '40px',
    lineHeight: '1.35',
  },
  BenefitsImageStyle: {
    maxWidth: '600px',
    width: '100%',
    m: '0 auto',
  },
};

export default BenefitsWithImage;

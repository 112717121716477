import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import { checkmark } from 'react-icons-kit/icomoon/checkmark';

// Components
// =============================================================================
import Text from 'common/src/components/Text';

// Styles
// =============================================================================
import BenefitsBlockWrapper, { BenefitsBlockItem } from './BenefitsBlock.style';

// Colours
// =============================================================================
import brand from 'common/src/theme/landing/brand';

// BenefitsBlock
// =============================================================================
const BenefitsBlock = ({ BenefitsBlockItemTextStyle, dataBenefits }) => {
  return (
    <BenefitsBlockWrapper>
      {dataBenefits.map((item, index) => (
        <BenefitsBlockItem key={`benefits-list-item-${index}`}>
          <Icon icon={checkmark} className="BenefitsListIcon" size={26} />
          <Text content={item.content} {...BenefitsBlockItemTextStyle} />
        </BenefitsBlockItem>
      ))}
    </BenefitsBlockWrapper>
  );
};

BenefitsBlock.propTypes = {
  BenefitsBlockItemTextStyle: PropTypes.object,
};

BenefitsBlock.defaultProps = {
  BenefitsBlockItemTextStyle: {
    fontSize: '20px',
    fontWeight: '400',
    textAlign: 'left',
    color: brand.brand8,
    letterSpacing: '-0.015em',
    lineHeight: '1.5',
    mb: 0,
  },
};

export default BenefitsBlock;

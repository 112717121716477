import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { landingTheme } from 'common/src/theme/landing';
import { ResetCSS } from 'common/src/assets/css/style';
import { useStaticQuery, graphql } from 'gatsby';

// Sections
import Header from '../containers/Landing/sections/Header';
import PromoWithForm from '../containers/Landing/sections/PromoWithForm';
import SignUpCallout from '../containers/Landing/sections/SignUpCallout';
import IntroWithFeatures from '../containers/Landing/sections/IntroWithFeatures';
import VideoWithFeatures from '../containers/Landing/sections/VideoWithFeatures';
import BenefitsWithImage from '../containers/Landing/sections/BenefitsWithImage';
import SetupSteps from '../containers/Landing/sections/SetupSteps';
import StaticFooter from '../containers/Landing/sections/StaticFooter';
import FeaturesWithPromo from '../containers/Landing/sections/FeaturesWithPromo';
import IntroWithImage from '../containers/Landing/sections/IntroWithImage';
import BenefitsWithForm from '../containers/Landing/sections/BenefitsWithForm';
import VideoWithPromo from '../containers/Landing/sections/VideoWithPromo';
import FeaturesList from '../containers/Landing/sections/FeaturesList';
import BenefitsWithVideo from '../containers/Landing/sections/BenefitsWithVideo';

// Styles
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/Landing/landing.style';

// Components
import SEO from '../components/seo';

// Smooth Scroll
if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]');
}

export default () => {
  const DataQuery = useStaticQuery(graphql`
    query {
      demoJson {
        Default {
          SEO {
            seoTitle
            seoDesc
          }
          FeaturesWithPromo {
            fwpLogo {
              publicURL
            }
            fwpHeading
            fwpText
            fwpButton
            fwpDataFeatures {
              title
              description
              icon {
                publicURL
              }
            }
          }
          VideoWithPromo {
            vwpLogo {
              publicURL
            }
            vwpHeading
            vwpText
            vwpButton
          }
          FeaturesList {
            flDataFeatures {
              title
              description
              icon {
                publicURL
              }
            }
          }
          IntroWithImage {
            iwiHeading
            iwiText
            iwiImage {
              publicURL
            }
          }
          PromoWithForm {
            promoLogo {
              publicURL
            }
            promoHeading
            promoText
            promoImage {
              publicURL
            }
            formHeading
            formLogo {
              publicURL
            }
            formPageType
            formLink
            formButton
          }
          BenefitsWithForm {
            bwfHeading
            bwfFormHeading
            bwfFormLogo {
              publicURL
            }
            bwfFormPageType
            bwfFormLink
            bwfFormButton
            bwfDataBenefits {
              content
            }
          }
          SignUpCallout {
            signUpHeading
            signUpText
            signUpButton
          }
          VideoWithFeatures {
            vwfDataFeatures {
              title
              description
              icon {
                publicURL
              }
            }
          }
          IntroWithFeatures {
            introHeading
            introText1
            introText2
            dataFeatures {
              title
              description
              icon {
                publicURL
              }
            }
          }
          BenefitsWithImage {
            benefitsHeading
            benefitsImage {
              publicURL
            }
            dataBenefits {
              content
            }
          }
          BenefitsWithVideo {
            bwvHeading
            bwvDataBenefits {
              content
            }
          }
          SetupSteps {
            setupHeading
            setupText
          }
          StaticFooter {
            footerAbout
            dataAwards {
              content
            }
          }
        }
      }
    }
  `);

  // Data
  const Data = DataQuery.demoJson.Default;

  // SEO
  const seoTitle = Data.SEO.seoTitle;
  const seoDesc = Data.SEO.seoDesc;

  // PromoWithForm
  const promoLogo = Data.PromoWithForm.promoLogo.publicURL;
  const promoHeading = Data.PromoWithForm.promoHeading;
  const promoText = Data.PromoWithForm.promoText;
  const promoImage = Data.PromoWithForm.promoImage.publicURL;
  const formHeading = Data.PromoWithForm.formHeading;
  const formLogo = Data.PromoWithForm.formLogo.publicURL;
  const formPageType = Data.PromoWithForm.formPageType;
  const formLink = Data.PromoWithForm.formLink;
  const formButton = Data.PromoWithForm.formButton;

  // FeaturesWithPromo
  const fwpLogo = Data.FeaturesWithPromo.fwpLogo.publicURL;
  const fwpHeading = Data.FeaturesWithPromo.fwpHeading;
  const fwpText = Data.FeaturesWithPromo.fwpText;
  const fwpButton = Data.FeaturesWithPromo.fwpButton;
  const fwpDataFeatures = Data.FeaturesWithPromo.fwpDataFeatures;

  // VideoWithFeatures
  const vwfDataFeatures = Data.VideoWithFeatures.vwfDataFeatures;

  // VideoWithPromo
  const vwpLogo = Data.VideoWithPromo.vwpLogo.publicURL;
  const vwpHeading = Data.VideoWithPromo.vwpHeading;
  const vwpText = Data.VideoWithPromo.vwpText;
  const vwpButton = Data.VideoWithPromo.vwpButton;

  // FeaturesList
  const flDataFeatures = Data.FeaturesList.flDataFeatures;

  // SignUpCallout
  const signUpHeading = Data.SignUpCallout.signUpHeading;
  const signUpText = Data.SignUpCallout.signUpText;
  const signUpButton = Data.SignUpCallout.signUpButton;

  // IntroWithFeatures
  const introHeading = Data.IntroWithFeatures.introHeading;
  const introText1 = Data.IntroWithFeatures.introText1;
  const introText2 = Data.IntroWithFeatures.introText2;
  const dataFeatures = Data.IntroWithFeatures.dataFeatures;

  // IntroWithImage
  const iwiHeading = Data.IntroWithImage.iwiHeading;
  const iwiText = Data.IntroWithImage.iwiText;
  const iwiImage = Data.IntroWithImage.iwiImage.publicURL;

  // BenefitsWithImage
  const benefitsHeading = Data.BenefitsWithImage.benefitsHeading;
  const benefitsImage = Data.BenefitsWithImage.benefitsImage.publicURL;
  const dataBenefits = Data.BenefitsWithImage.dataBenefits;

  // BenefitsWithVideo
  const bwvHeading = Data.BenefitsWithVideo.bwvHeading;
  const bwvDataBenefits = Data.BenefitsWithVideo.bwvDataBenefits;

  // BenefitsWithForm
  const bwfHeading = Data.BenefitsWithForm.bwfHeading;
  const bwfFormHeading = Data.BenefitsWithForm.bwfFormHeading;
  const bwfFormLogo = Data.BenefitsWithForm.bwfFormLogo.publicURL;
  const bwfFormPageType = Data.BenefitsWithForm.bwfFormPageType;
  const bwfFormLink = Data.BenefitsWithForm.bwfFormLink;
  const bwfFormButton = Data.BenefitsWithForm.bwfFormButton;
  const bwfDataBenefits = Data.BenefitsWithForm.bwfDataBenefits;

  // SetupSteps
  const setupHeading = Data.SetupSteps.setupHeading;
  const setupText = Data.SetupSteps.setupText;

  // StaticFooter
  const footerAbout = Data.StaticFooter.footerAbout;
  const dataAwards = Data.StaticFooter.dataAwards;

  return (
    <ThemeProvider theme={landingTheme}>
      <Fragment>
        <SEO title={seoTitle} description={seoDesc} />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Header />
          <FeaturesWithPromo
            fwpLogo={fwpLogo}
            fwpHeading={fwpHeading}
            fwpText={fwpText}
            fwpButton={fwpButton}
            fwpDataFeatures={fwpDataFeatures}
          />
          <SignUpCallout
            signUpHeading={signUpHeading}
            signUpText={signUpText}
            signUpButton={signUpButton}
          />
          <VideoWithFeatures vwfDataFeatures={vwfDataFeatures} />
          <VideoWithPromo
            vwpLogo={vwpLogo}
            vwpHeading={vwpHeading}
            vwpText={vwpText}
            vwpButton={vwpButton}
          />
          <IntroWithImage
            iwiHeading={iwiHeading}
            iwiText={iwiText}
            iwiImage={iwiImage}
          />
          <BenefitsWithForm
            bwfHeading={bwfHeading}
            bwfDataBenefits={bwfDataBenefits}
            bwfFormLogo={bwfFormLogo}
            bwfFormHeading={bwfFormHeading}
            bwfFormPageType={bwfFormPageType}
            bwfFormLink={bwfFormLink}
            bwfFormButton={bwfFormButton}
          />
          <FeaturesList flDataFeatures={flDataFeatures} />
          <PromoWithForm
            promoLogo={promoLogo}
            promoHeading={promoHeading}
            promoText={promoText}
            promoImage={promoImage}
            formHeading={formHeading}
            formLogo={formLogo}
            formPageType={formPageType}
            formLink={formLink}
            formButton={formButton}
          />
          <IntroWithFeatures
            introHeading={introHeading}
            introText1={introText1}
            introText2={introText2}
            dataFeatures={dataFeatures}
          />
          <BenefitsWithImage
            benefitsHeading={benefitsHeading}
            benefitsImage={benefitsImage}
            dataBenefits={dataBenefits}
          />
          <SetupSteps setupHeading={setupHeading} setupText={setupText} />
          <BenefitsWithVideo
            bwvHeading={bwvHeading}
            bwvDataBenefits={bwvDataBenefits}
          />
          <StaticFooter footerAbout={footerAbout} dataAwards={dataAwards} />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

import React from 'react';
import PropTypes from 'prop-types';

// Components
// =============================================================================
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';

// Styles
// =============================================================================
import IntroBlockWrapper from './IntroBlock.style';

// Colours
// =============================================================================
import brand from 'common/src/theme/landing/brand';

// IntroBlock
// =============================================================================
const IntroBlock = ({
  IntroBlockHeadingStyle,
  IntroBlockTextStyle,
  introHeading,
  introText1,
  introText2,
}) => {
  return (
    <IntroBlockWrapper>
      <Heading {...IntroBlockHeadingStyle} content={introHeading} />
      {introText1 ? (
        <Text {...IntroBlockTextStyle} content={introText1} />
      ) : null}
      {introText2 ? (
        <Text {...IntroBlockTextStyle} content={introText2} />
      ) : null}
    </IntroBlockWrapper>
  );
};

IntroBlock.propTypes = {
  IntroBlockHeadingStyle: PropTypes.object,
  IntroBlockTextStyle: PropTypes.object,
};

IntroBlock.defaultProps = {
  IntroBlockHeadingStyle: {
    fontSize: ['24px', '24px', '26px', '26px', '28px'],
    fontWeight: '600',
    color: brand.brand8,
    textAlign: 'center',
    letterSpacing: '-0.025em',
    mb: '40px',
    lineHeight: '1.6',
  },
  IntroBlockTextStyle: {
    fontSize: ['17px', '17px', '18px', '18px', '20px'],
    color: brand.brand8,
    fontWeight: '400',
    textAlign: 'center',
    lineHeight: '1.6',
    mb: '20px',
  },
};

export default IntroBlock;

import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const BenefitsBlockWrapper = styled.div`
  text-align: left;
  position: relative;
`;

export const BenefitsBlockItem = styled.div`
  display: flex;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  .BenefitsListIcon {
    color: ${themeGet('brand.brand6')};
    margin-right: 15px;
  }
`;

export default BenefitsBlockWrapper;

import React from 'react';
import PropTypes from 'prop-types';

// UI
// =============================================================================
import Container from 'common/src/components/UI/Container';

// Components
// =============================================================================
import Box from 'common/src/components/Box';

// Blocks
// =============================================================================
import IntroBlock from './blocks/Intro';
import FeaturesBlock from './blocks/Features';

// Styles
// =============================================================================
import SectionAreaWrapper, { SectionArea } from './IntroWithFeatures.style';

// IntroWithFeatures
// =============================================================================
const IntroWithFeatures = ({
  SectionWrapperStyle,
  RowStyle,
  RowFixedStyle,
  ColStyle,
  ColOneStyle,
  ColTwoStyle,
  introHeading,
  introText1,
  introText2,
  dataFeatures,
}) => {
  return (
    <Box {...SectionWrapperStyle} id="intro_features">
      <Container fullWidth>
        <Box {...RowStyle} {...RowFixedStyle}>
          <Box {...ColStyle} {...ColOneStyle}>
            <SectionAreaWrapper>
              <SectionArea>
                <IntroBlock
                  introHeading={introHeading}
                  introText1={introText1}
                  introText2={introText2}
                />
              </SectionArea>
            </SectionAreaWrapper>
          </Box>
          <Box {...ColStyle} {...ColTwoStyle}>
            <SectionAreaWrapper>
              <SectionArea>
                <FeaturesBlock dataFeatures={dataFeatures} />
              </SectionArea>
            </SectionAreaWrapper>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

IntroWithFeatures.propTypes = {
  SectionWrapperStyle: PropTypes.object,
  RowStyle: PropTypes.object,
  RowFixedStyle: PropTypes.object,
  ColStyle: PropTypes.object,
  ColOneStyle: PropTypes.object,
  ColTwoStyle: PropTypes.object,
};

IntroWithFeatures.defaultProps = {
  SectionWrapperStyle: {
    as: 'section',
    bg: '#fff',
    pt: ['20px', '20px', '30px', '40px', '40px'],
    pb: ['20px', '20px', '30px', '40px', '40px'],
  },
  RowStyle: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'top',
    justifyContent: 'center',
    height: ['auto', 'auto', 'auto', '560px', '560px'],
  },
  RowFixedStyle: {
    maxWidth: '1360px',
    width: '100%',
    m: '0 auto',
  },
  ColStyle: {
    width: ['100%', '100%', '100%', '50%', '50%'],
    height: '100%',
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ColOneStyle: {},
  ColTwoStyle: {},
};

export default IntroWithFeatures;
